@import 'libs/theming/src/lib/assets/styles/imports/vars';
@import 'libs/theming/src/lib/assets/styles/imports/clr-color-vars';

/* svg colors */
$svg-yellow: #f4d011 !default;
$svg-orange: #ffa400 !default;
$orange: #f5a623 !default;

$header-background-customer: cadetblue !default;

/* colors for matrix cell icons */
$matrix-green: $clr-green-4;
$matrix-yellow: $clr-yellow-6;
$matrix-orange: $clr-orange-6;
$matrix-red: $clr-red-3;

/* Header */
:root {
	--clr-header-bg-color: #{$header-background} !important;

	// chrome default user agend placeholder color
	// way better contrast than clarity default (#4f6169)
	--clr-forms-placeholder-color: #757575 !important;

	// overwrite clarity using brown for orange badges (at least in version 13.14.1)
	--clr-badge-orange-bg-color: #{$orange-dark} !important;
	--clr-label-orange-bg-color: #{$orange-dark} !important;
}

.XGcustomer-theme {
	&__header {
		clr-header.header {
			background-color: $header-background-customer;
		}
	}
}

.XGquestion-radio-group {
	label {
		flex: 1;
		margin-right: 2em;
		width: auto !important;
	}

	.clr-form-control {
		justify-content: space-between;
	}

	.clr-control-container {
		width: auto !important;
	}
}

.XGformgroup-max-width-wrapper form {
	margin: 0 auto;
	max-width: 800px;
}

.XGsvg-frontends-control-wrapper-unlimited-width {
	.mac-input,
	.mac-control-container {
		/* overwrite mac lib styles, that makes wrapper as wide as form controls */
		max-width: none !important;
	}
}

/* overwrite mac widget headline for ellipsis cut headline of widget */
.mac-widget__headline {
	/* overwrite with important because component style says inline-flex */
	display: block !important;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}

/* use important to overwrite clarity style: '.clr-form-horizontal .clr-form-control' */
/* todo - remove this and add mac-form-control class to checkbox in mac-lib  */
.XGlabel-above-control .clr-form-control,
.XGlabel-above-control .mac-form-control {
	flex-direction: column !important;
}

/* [CAUTION] a super dialog (dialog overlaying total site) must add this class to body and remove it after it is closed. */
.XGsuperdialog {
	.mac-custom-mat-dialog-container {
		max-width: 95vw !important;
		max-height: 95vh !important;
	}

	.mac-custom-dialog {
		width: auto !important;
	}

	.modal-body {
		max-height: 78vh !important;
	}

	.clr-textarea {
		min-height: auto !important;
	}

	td:not(td:last-child) {
		border-right: 1px solid #e8e8e8;
	}

	.XGwidth-63 {
		&,
		.mac-subtext-wrapper,
		.mac-input {
			min-width: auto;
			width: 63px !important;
		}
	}

	.XGwidth-200 {
		&,
		.mac-subtext-wrapper,
		.mac-input {
			min-width: auto;
			width: 200px !important;
		}

		.ng-select {
			max-width: 100%;
		}
	}

	.XGwidth-162 {
		&,
		.mac-subtext-wrapper,
		.mac-input {
			min-width: auto;
			width: 162px !important;
		}

		.ng-select {
			max-width: 100%;
		}
	}

	.XGwidth-155 {
		&,
		.mac-subtext-wrapper,
		.mac-input {
			min-width: auto;
			width: 155px !important;
		}

		.ng-select {
			max-width: 100%;
		}
	}

	.XGwidth-85 {
		&,
		.mac-subtext-wrapper,
		.mac-input {
			min-width: auto;
			width: 85px !important;
		}
	}

	.XGwidth-100 {
		&,
		.mac-subtext-wrapper,
		.mac-input {
			min-width: auto;
			width: 120px !important;
		}
	}

	.XGwidth-250 {
		&,
		.mac-subtext-wrapper,
		.mac-input {
			min-width: auto;
			width: 250px !important;
		}
	}

	.clr-form {
		padding: 0 !important;
	}
}
