//needed for a tags with (click) without href
a {
	cursor: pointer;
}

// increase specificity, to overwrite styles on a-tag
.mac-txtlink.mac-txtlink {
	cursor: pointer;
	color: darken(#007cbb, 15%);
	text-decoration: underline;

	&:hover {
		color: #007cbb;
		text-decoration: none;
	}
}
