// fixed clarity style on chart <header/> - Elements
.chart-legend .legend-title {
	background: none;
	color: initial;
	display: block;
	height: auto;
}

.advanced-pie-legend .legend-items-container .legend-item {
	margin-top: 1%;
}
