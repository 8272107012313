/**
 * This is the only place where clr-variables should be referenced directly.
 */

@import 'clr-color-vars';

/* clarity colors */
$clarity-blue: $clr-blue-4 !default;
$clr-color-action-600: $clr-blue-4 !default;

/* svg colors */
$svg-yellow: #f4d011 !default;
$svg-orange: #ffa400 !default;

/* colors */
$light-grey: $clr-neutral-grey-6 !default;

$control-helper-text-color: $clr-neutral-grey-0 !default;

$orange: #f5a623 !default;
$orange-dark: #e8811f !default;
$orange-light: #ffdca3 !default;
$orange-medium: #ffaf2f !default;

$header-background: #595959 !default;
$header-background-user: teal !default;
$header-background-edit: indianred !default;

$white: $clr-white !default;

$success: $clr-green-3 !default;
$warning: $clr-yellow-0 !default;
$danger: $clr-red-3 !default;

/* error colors */
$invalid-bg: $clr-red-9 !default;
$invalid-border: $clr-red-7 !default;
$error-red: $clr-red-3 !default;

$customerContextTaskbarColor: dimgray !default;

/* breakpoints */
$break-max-xs: 575px !default;
$break-min-m: 769px !default;

/* used in login component */
$break-small-device-md: 810px !default;
$break-small-device-sm: 767px !default;
$break-small-device-xs: 725px !default;
$break-small-device-xss: 550px !default;
$break-small-device-height-sm: 900px !default;

/* used in process-lading-page component */
$column-width-max: 1000px !default;

/* height of clr-header height */
$header-height: 60px !default;

/* height of clr-tabs links height */
$clr-tabs-link-height: 36px !default;
/* height of tab-bar navigation/header */
$clr-tabs-navi-default-height: 4.1rem !default;
