.mac-wizard {
	&--fullheight {
		.modal-body {
			flex: 1; // overwriting flex: 1 1 auto (to allow child to use full height)
		}

		.clr-wizard-content {
			height: 100%; // adding height 100%
		}
	}
}
