.mac-form-control {
	// Used to overwrite the clr set big margin-top
	margin-top: 5px !important;
}

.clr-form-control {
	// Used to overwrite the clr set big margin-top
	margin-top: 5px !important;
}

.svg-frontends-required-marker {
	color: #c92100;
	font-size: 0.58479532rem;
	line-height: 0.5rem;
	margin-left: 0.1rem;
}

.clr-control-container {
	width: 100%;

	.clr-textarea-wrapper .clr-textarea {
		width: 100%;
		min-height: 200px;
	}
}

.mac-form-control--footnote {
	margin: 0.5rem;
	max-width: 442px;
	width: 100%;
}
