.mac- {
	&matdialogcontent {
		// mat dialog has max-height: 65vh
		min-height: 50vh;
		min-width: 50vw;
	}
}

/* TODO - refactor using mac- classes */

//display buttons in material dialog on right-side
.mat-dialog-actions {
	justify-content: flex-end;
}

//set std backdrop to clarity styling
.cdk-overlay-dark-backdrop.cdk-overlay-dark-backdrop {
	background: rgba(31, 31, 31, 0.85);
}

// This enables appendTo with correct values. With the default position: static that's possible
.cdk-overlay-pane {
	position: absolute !important;
}

.mat-dialog-title {
	color: #000;
	font-family: Metropolis, 'Avenir Next', 'Helvetica Neue', Arial, sans-serif;
	font-size: 0.916667rem;
	font-weight: 200;
	letter-spacing: normal;
	line-height: 1rem;
	margin: 0;
	padding: 0 0.125rem;
}
