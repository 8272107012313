@import 'mixins';

.cdk-overlay-container {
	// We have to overwrite clarity popover z-index, which is 5000, in order to show date-picker in datagrid filters.
	z-index: 5001;
}

.owl-dt-container {
	font-size: 1em;
}

.mac-date-picker-container {
	@include flex_row();
}

.mac-date-picker-label {
	flex: 1 1 auto;
}
