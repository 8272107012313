.mac-custom-mat-dialog-container .mat-dialog-container {
	//This is needed to remove padding from material dialog in order to make the clr-wizard look correct
	padding: 0 !important;
	// workaround for us apparently not wanting to use mat-dialog how it is intended... (using mat-dialog-content tag or pipe)
	max-height: 95vh;
}

//This is needed to remove these classes from clr-wizard
.mac-custom-clr-modal .modal {
	align-items: initial;
	bottom: initial;
	display: initial;
	flex-direction: initial;
	justify-content: initial;
	left: initial;
	padding: initial;
	position: initial;
	right: initial;
	top: initial;
	z-index: initial;
}

.mac-custom-clr-modal .modal-backdrop {
	background-color: initial;
	bottom: initial;
	left: initial;
	opacity: initial;
	position: initial;
	right: initial;
	top: initial;
	z-index: initial;
}

//This is the custom backdrop class, which uses clr backdrop color
.mac-custom-modal-backdrop {
	background: rgba(48, 48, 48, 0.85);
}
