.mac-input {
	max-width: 300px;
	min-width: 200px;
	width: 100%;
}

.mac-subtext-wrapper {
	max-width: 500px;
	min-width: 200px;
	width: 100%;
}
