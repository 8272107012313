/* overwriting clr tabs styles */

$tabsEllipsisMenuWidth: 50px;

.mac-tabs {
	&--fullheight {
		// inside tabs there is a section.role="tabpanel", which is generated by `clr-tab-content`
		// after clarity added normalize.css, sections are `display: block`, for full height / scrollable tab content
		> section[role='tabpanel'] {
			display: inline;
		}

		// Specificity increased to not overwrite this in sub tabs
		> ul .nav-item {
			flex: 1 1;
			margin: 0;
			text-align: center;
		}
	}

	&--menu-last {
		// Specificity increased to not overwrite this in sub tabs
		> ul .nav-item {
			margin: 0;
			max-width: none; // overwrite `max-width: 15rem;` from clarity `_buttons.clarity.scss` on class `.btn`
			width: calc(50% - (#{$tabsEllipsisMenuWidth} / 2));

			&:last-of-type {
				width: $tabsEllipsisMenuWidth;
			}
		}
	}
}
