/**
 * The color variables need to be in sync with src/app/utils/enums/clr-colors.enum.ts
 * These variables should only be used to configure custom color variables in src/assets/styles/imports/color-vars.scss
 */

// Red
$clr-red-0: #f52f22 !default;
$clr-red-1: #a32100 !default;
$clr-red-2: #c92100 !default;
$clr-red-3: #e12200 !default;
$clr-red-4: #f52f22 !default;
$clr-red-5: #f54f47 !default;
$clr-red-6: #f76f6c !default;
$clr-red-7: #f89997 !default;
$clr-red-8: #f8b7b6 !default;
$clr-red-9: #f5dbd9 !default;
$clr-red-10: #fff0ee !default;

// Pink
$clr-pink-0: #f1428a !default;
$clr-pink-1: #9b0d54 !default;
$clr-pink-2: #b0105b !default;
$clr-pink-3: #c41261 !default;
$clr-pink-4: #d91468 !default;
$clr-pink-5: #ed186f !default;
$clr-pink-6: #f1428a !default;
$clr-pink-7: #f46ca5 !default;
$clr-pink-8: #f897bf !default;
$clr-pink-9: #fbc1da !default;
$clr-pink-10: #ffebf5 !default;

// Purple
$clr-purple-0: #781da0 !default;
$clr-purple-1: #4d007a !default;
$clr-purple-2: #660092 !default;
$clr-purple-3: #781da0 !default;
$clr-purple-4: #8939ad !default;
$clr-purple-5: #9b56bb !default;
$clr-purple-6: #ad73c8 !default;
$clr-purple-7: #be90d6 !default;
$clr-purple-8: #d0ace4 !default;
$clr-purple-9: #e1c9f1 !default;
$clr-purple-10: #f3e6ff !default;

// Ultramarine
$clr-ultramarine-0: #343dac !default;
$clr-ultramarine-1: #0f1e82 !default;
$clr-ultramarine-2: #1a23a0 !default;
$clr-ultramarine-3: #343dac !default;
$clr-ultramarine-4: #4e56b8 !default;
$clr-ultramarine-5: #6870c4 !default;
$clr-ultramarine-6: #838acf !default;
$clr-ultramarine-7: #9da3db !default;
$clr-ultramarine-8: #b7bde7 !default;
$clr-ultramarine-9: #d1d6f3 !default;
$clr-ultramarine-10: #ebf0ff !default;

// Blue
$clr-blue-0: #0065ab !default;
$clr-blue-1: #003d79 !default;
$clr-blue-2: #004d8a !default;
$clr-blue-3: #0065ab !default;
$clr-blue-4: #1976d2 !default;
$clr-blue-4-old: #0079b8 !default;
$clr-blue-5: #0095d3 !default;
$clr-blue-6: #49afd9 !default;
$clr-blue-7: #89cbdf !default;
$clr-blue-8: #a6d8e7 !default;
$clr-blue-9: #c5e5ef !default;
$clr-blue-10: #e1f1f6 !default;

// Cyan
$clr-cyan-0: #00b7d6 !default;
$clr-cyan-1: #004a70 !default;
$clr-cyan-2: #005680 !default;
$clr-cyan-3: #006690 !default;
$clr-cyan-4: #0081a7 !default;
$clr-cyan-5: #009cbf !default;
$clr-cyan-6: #00b7d6 !default;
$clr-cyan-7: #36c9e1 !default;
$clr-cyan-8: #6ddbeb !default;
$clr-cyan-9: #a3edf6 !default;
$clr-cyan-10: #ccfbff !default;

// Teal
$clr-teal-0: #00968b !default;
$clr-teal-1: #006668 !default;
$clr-teal-2: #007e7a !default;
$clr-teal-3: #00968b !default;
$clr-teal-4: #00ab9a !default;
$clr-teal-5: #00bfa9 !default;
$clr-teal-6: #00d4b8 !default;
$clr-teal-7: #38dfc8 !default;
$clr-teal-8: #6fead9 !default;
$clr-teal-9: #a7f4e9 !default;
$clr-teal-10: #defff9 !default;

// Green
$clr-green-0: #48960c !default;
$clr-green-1: #1d5100 !default;
$clr-green-2: #266900 !default;
$clr-green-3: #2f8400 !default;
$clr-green-4: #48960c !default;
$clr-green-5: #62a420 !default;
$clr-green-6: #60b515 !default;
$clr-green-7: #85c81a !default;
$clr-green-8: #aadb1e !default;
$clr-green-9: #c7e59c !default;
$clr-green-10: #dff0d0 !default;

// Yellow
$clr-yellow-0: #fdcf08 !default;
$clr-yellow-1: #c47d00 !default;
$clr-yellow-2: #d28f00 !default;
$clr-yellow-3: #dfa100 !default;
$clr-yellow-4: #edb200 !default;
$clr-yellow-5: #fac400 !default;
$clr-yellow-6: #fdd006 !default;
$clr-yellow-7: #fdcf08 !default;
$clr-yellow-8: #ffe860 !default;
$clr-yellow-9: #fef3b5 !default;
$clr-yellow-10: #fffce8 !default;

// Orange
$clr-orange-0: #f57600 !default;
$clr-orange-1: #aa4500 !default;
$clr-orange-2: #c25400 !default;
$clr-orange-3: #d36000 !default;
$clr-orange-4: #e46c00 !default;
$clr-orange-5: #f57600 !default;
$clr-orange-6: #ff8400 !default;
$clr-orange-7: #ff9c32 !default;
$clr-orange-8: #ffb565 !default;
$clr-orange-9: #ffcd97 !default;
$clr-orange-10: #ffe5c9 !default;

// Red-orange
$clr-red-orange-0: #ee4a08 !default;
$clr-red-orange-1: #cd3517 !default;
$clr-red-orange-2: #de400f !default;
$clr-red-orange-3: #ee4a08 !default;
$clr-red-orange-4: #ff5500 !default;
$clr-red-orange-5: #ff681c !default;
$clr-red-orange-6: #ff8142 !default;
$clr-red-orange-7: #ff9a69 !default;
$clr-red-orange-8: #ffb38f !default;
$clr-red-orange-9: #ffccb5 !default;
$clr-red-orange-10: #ffe5dc !default;

// Warm-grey
$clr-warm-grey-0: #80746d !default;
$clr-warm-grey-1: #5b4d47 !default;
$clr-warm-grey-2: #6c5f59 !default;
$clr-warm-grey-3: #80746d !default;
$clr-warm-grey-4: #948981 !default;
$clr-warm-grey-5: #a89e95 !default;
$clr-warm-grey-6: #bbb3a9 !default;
$clr-warm-grey-7: #cfc8bd !default;
$clr-warm-grey-8: #e3ddd1 !default;
$clr-warm-grey-9: #f4f1e6 !default;
$clr-warm-grey-10: #faf9f5 !default;

// Neutral-grey
$clr-neutral-grey-0: #737373 !default;
$clr-neutral-grey-1: #313131 !default;
$clr-neutral-grey-2: #444444 !default;
$clr-neutral-grey-3: #565656 !default;
$clr-neutral-grey-4: #737373 !default;
$clr-neutral-grey-5: #9a9a9a !default;
$clr-neutral-grey-6: #cccccc !default;
$clr-neutral-grey-7: #dddddd !default;
$clr-neutral-grey-8: #eeeeee !default;
$clr-neutral-grey-9: #f2f2f2 !default;
$clr-neutral-grey-10: #fafafa !default;

// Cool-grey
$clr-cool-grey-0: #61717d !default;
$clr-cool-grey-1: #25333d !default;
$clr-cool-grey-2: #314351 !default;
$clr-cool-grey-3: #495a67 !default;
$clr-cool-grey-4: #61717d !default;
$clr-cool-grey-5: #798893 !default;
$clr-cool-grey-6: #919fa8 !default;
$clr-cool-grey-7: #a9b6be !default;
$clr-cool-grey-8: #c1cdd4 !default;
$clr-cool-grey-9: #d9e4ea !default;
$clr-cool-grey-10: #f3f6fa !default;

$clr-black: #000000 !default;
$clr-white: #ffffff !default;
