/* Your Application Theme File, which sets scss variables */
@import './theme.scss';

/* Import lib styles (including clr)  */
@import 'libs/theming/src/lib/assets/styles/styles.scss';

/**
 * [NOTE] global styles to be used by this application
 */

/* increase specificity to always not display element */
.XGhidden {
	display: none !important;
}

/* overwrite a clarity icon style, by using global class to add a gradient, that is specified in the component using it */
.XGiconwrapper svg {
	fill: url('#XCiconwrapper-gradient');
}

/* increase specificity to overwrite mac-data-header which is card header */
.mac-data-header.mac-data-header {
	.XGcard-processable & {
		background-color: $clr-red-9;
	}
	.XGcard-in-process & {
		background-color: $clr-orange-9;
	}
	.XGcard-processed & {
		background-color: $clr-green-9;
	}
}

/* global styling for matrix cell icons */
.XGis-success {
	color: $matrix-green;
}

.XGis-minor-warning {
	color: $matrix-yellow;
}

.XGis-major-warning {
	color: $matrix-orange;
}

.XGis-error {
	color: $matrix-red;
}

/* for full height `clr-tabs` (for example when showing table in `clr-tabs`) must adopt styles from full height table */
.XGclr-tabs-full-height {
	&,
	> .tab-content {
		@include flex_column();

		height: 100%;
	}
}

.XGcontrol-wrapper--fullsize.XGcontrol-wrapper--fullsize .mac-control-container {
	max-width: none;
}

/**
 * Quarter cards on budget-details page need a different background-color.
 * svg-frontends-card ViewEncapsulation prevents us from doing this inside the component.
 */
.XGbudget-quarter-card .card-header {
	background-color: lightblue !important;
}
