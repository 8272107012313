@import 'mixins';

.data-grid-wrapper clr-datagrid,
.data-grid-wrapper .datagrid-host {
	height: 100% !important;
	z-index: 0;
}

.datagrid {
	z-index: 0; // capsulate z-index for all children
}

.mac-datagrid-wrapper {
	@include flex_column();

	flex: 1;
}

.mac-datagrid-footer {
	// overwrite clr class datagrid-footer-description to make it horizontally scrollable
	.datagrid-footer-description {
		@include flex_row();

		justify-content: space-between;
		max-width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
	}

	&__item {
		flex: 1;
		margin: 0 0.5em;
	}

	&--meta {
		// datagrid-meta is a second footer in datagrid, which should not have a doubled clr-dg-column-toggle
		clr-dg-column-toggle {
			display: none;
		}
	}
}

.mac-datagrid {
	// This is needed to overwrite clarity set custom height
	height: 100% !important;

	// Maybe need if table view is not allowed to allocate as much height as possible.
	// This ensures that the loading indicator has enough vertical space.
	.datagrid-table-wrapper {
		min-height: 6rem;
	}

	// TODO - min-width is set for every cell and using important does create a very high specificity
	// TODO - minimum at e-vignette calendar table, this must be overwritten
	// TODO - there should be at least a class, which must be added to datagrid when wanting to use this min-width, which could be left out on e-vignette table
	clr-dg-cell {
		font-family: Consolas, Monaco, Courier, monospace !important;
		min-width: 150px !important;
	}

	// overwrite margin-top on `.datagrid` (clarity dynamically generated dom node) to ensure actionbar border and `.datagrid` are bound together
	&--top0 {
		> .datagrid {
			margin-top: 0;
		}
	}
}

/* datagrid action bar */
.datagrid-action-bar {
	&.hasResetFilterButton {
		@include flex_row();

		justify-content: space-between;

		.btn-group:last-child {
			margin-right: 0;
		}

		.resetFilterButton {
			display: none;

			@include media-breakpoint-up-lg {
				display: inline-flex;
			}
		}
	}
}
