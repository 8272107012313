@import 'vars';

// Global style for hiding an element.
// !important is required to use it with ng-class. Otherwise is overwritten by display:block/flex etc.
.mac-hidden {
	display: none !important;
}

.mac-footnote {
	font-size: 80%; // just like small-html-tall <small></small>
	font-style: italic;
	line-height: 1em;
}

html {
	// This prevents outline on focus of elements by browser
	:focus {
		outline: none;
	}
}
