// general card style
.card {
	overflow: auto;
}

/* overwrite card styles Text responsive Cards */
@media (max-width: $break-max-xs) {
	.responsive-card .card-header,
	.card-block {
		display: inline-block;
	}

	.responsive-card .card-block {
		display: inline-block;
		font-size: 0.7em;
		font-weight: bold;
	}
}
