.w-100 {
	width: 100% !important;
}

.h-100 {
	height: 100% !important;
}

.mw-100 {
	max-width: 100% !important;
}

.mh-100 {
	max-height: 100% !important;
}
