/* utilities */
@import 'utilities/display';
@import 'utilities/flex';
@import 'utilities/position';
@import 'utilities/sizing';
@import 'utilities/spacing';
@import 'utilities/text';

/* custom styles */
@import 'imports/vars';
@import 'imports/general';
@import 'imports/mixins';

@import 'imports/buttons';
@import 'imports/cards';
@import 'imports/charts';
@import 'imports/clr';
@import 'imports/clr-accordion';
@import 'imports/clr-datagrid';
@import 'imports/clr-forms';
@import 'imports/clr-modal';
@import 'imports/clr-tabs';
@import 'imports/clr-wizard';
@import 'imports/forms';
@import 'imports/links';
@import 'imports/mat';
@import 'imports/ng-select';
@import 'imports/owl-datetime';
@import 'imports/tooltips';

@import 'imports/custom-dialog';

@import 'imports/quill';

// Clarity Component SCSS
@import '../../../../../../node_modules/@clr/ui/src/utils/components.clarity';

// quill styles
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
