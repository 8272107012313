@import '../../../../../../../node_modules/@clr/ui/src/layout/grid/mixins/breakpoint';

// this is used to fix inconsistent implementations of flexbox in firefox and chrome
@mixin flexboxFix($isRow) {
	@if $isRow {
		min-width: 0;
	} @else {
		min-height: 0;
	}
}

@mixin flex_row {
	@include flexboxFix(true);

	display: flex;
	flex-direction: row;
}

@mixin flex_column {
	@include flexboxFix(false);

	display: flex;
	flex-direction: column;
}

/*
 * 	sm: 544px (default)
 *	md: 768px (default)
 *	lg: 992px (default)
 *	xl: 1200px (default)
*/
$clr-grid-breakpoints: (
	xs: 0,
	sm: 600px,
	md: 960px,
	lg: 1280px,
	xl: 1920px,
);

@mixin media-breakpoint-down-xs {
	@include clr-media-breakpoint-down(xs, $clr-grid-breakpoints) {
		@content;
	}
}

@mixin media-breakpoint-down-sm {
	@include clr-media-breakpoint-down(sm, $clr-grid-breakpoints) {
		@content;
	}
}

@mixin media-breakpoint-down-md {
	@include clr-media-breakpoint-down(md, $clr-grid-breakpoints) {
		@content;
	}
}

@mixin media-breakpoint-down-lg {
	@include clr-media-breakpoint-down(lg, $clr-grid-breakpoints) {
		@content;
	}
}

@mixin media-breakpoint-up-sm {
	@include clr-media-breakpoint-up(sm, $clr-grid-breakpoints) {
		@content;
	}
}

@mixin media-breakpoint-up-md {
	@include clr-media-breakpoint-up(md, $clr-grid-breakpoints) {
		@content;
	}
}

@mixin media-breakpoint-up-lg {
	@include clr-media-breakpoint-up(lg, $clr-grid-breakpoints) {
		@content;
	}
}

@mixin media-breakpoint-up-xl {
	@include clr-media-breakpoint-up(xl, $clr-grid-breakpoints) {
		@content;
	}
}
