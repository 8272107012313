// overwrite clarity modal style to unset overflow
// this is used to do not attach scrollbars to modal, when ng-select's dropdown is active
.mac-modal {
	&--overflow-fix {
		.modal-body {
			overflow-x: inherit;
			overflow-y: inherit;
		}
	}

	&--relative-body {
		.modal-body {
			position: relative;
		}
	}

	&--fullheight {
		// modal-body of clr-modal has max-height: 70vh
		.modal-body {
			height: 70vh;
		}
	}

	// this fix is needed when clarity modal overlays material dialog
	// material modal z-index is custom increased to 1051, because owl datetime picker must overlay clarity modal/wizard
	&--zindexfix {
		> .modal {
			z-index: 1052;
		}
	}
}
