// vars are in vars.sass which are imported into general styles.sass

button {
	&:focus {
		outline: 0 !important;
	}
	&.btn-flex {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
}

.btn-svg,
.btn-svg .btn {
	color: $white !important;
	border-color: $orange-medium !important;
	background-color: $orange-medium !important;

	// overwriting clarity styles after clr 12 update (only for svg button)
	& > cds-icon {
		color: white !important;
	}

	&:hover {
		background-color: $orange-dark !important;
	}
}

.btn-svg-outline,
.btn-svg-outline .btn {
	color: $orange !important;
	border-color: $orange !important;

	&:hover {
		background-color: $orange-light !important;
	}
}

.radio.btn input[type='radio']:checked + label,
.radio.btn input[type='radio']:checked + label {
	// The important is needed here, because clarity also uses the class descriptor twice to increase specificity
	background-color: $orange !important;

	// edge: display block needed to place label inside btn
	display: block !important;
}

.radio.btn.svg_radiobutton {
	color: $orange !important;
	border-color: $orange !important;

	&:hover {
		background-color: $orange-light !important;
	}

	> input[type='radio']:checked {
		+ label {
			background-color: $orange;
		}
	}
}

// header clr styles overwrite dropdown styles after clr12 update
header .settings > .dropdown .dropdown-toggle.nav-text,
header .header-actions > .dropdown .dropdown-toggle.nav-text,
.header .settings > .dropdown .dropdown-toggle.nav-text,
.header .header-actions > .dropdown .dropdown-toggle.nav-text {
	padding-right: 0.6rem !important;
}
