.ng-select {
	.ng-select-container .ng-value-container {
		.ng-input.ng-input > input {
			width: 80%;
		}

		// fix to use in reactive forms with bootstrap classes
		.ng-value {
			.ng-value-label,
			.ng-value-icon {
				margin: 0;
			}
		}
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
		white-space: normal;
	}

	&.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder.ng-placeholder,
	&.ng-select-focused .ng-arrow-wrapper .ng-arrow.ng-arrow,
	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
		color: $clarity-blue;
	}

	&.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
		background-color: $clarity-blue;
	}

	&.ng-select-focused.ng-select-container.ng-select-container,
	.ng-select-container.ng-select-container {
		&:after {
			border-color: $clarity-blue;
		}
	}

	form.svg_form.cstm-invalid &.ng-invalid .ng-select-container:after,
	&.ng-invalid.ng-touched .ng-select-container:after {
		border-color: $error-red;
	}

	.ng-clear-zone {
		text-align: center;
		width: 22px;
		z-index: 2;
	}

	.ng-clear-zone .ng-clear {
		display: block;
		font-size: 22px;
		line-height: 1em;
	}
}

.ng-dropdown-panel {
	// We have to overwrite clarity popover z-index, which is 5000, in order to show date-picker in datagrid filters.
	// Specificity needs to be increased to overwrite z-index
	z-index: 5001 !important;
}

.datagrid-filter .ng-select {
	max-width: 300px;
}

/* Overwriting custom filter input */
.ng-select.searchable .ng-control .ng-value-container .ng-input > input[role='combobox'] {
	width: 88%;
}

.mac-ng-select {
	&__image {
		display: inline;
		margin-right: 1em;
		max-height: 30px;
		padding: 0.1em;
		width: 30px;
	}

	&__imagetext {
		display: inline;
	}
}
