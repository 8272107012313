.mac-vertical-nav {
	text-align: center;
}

// overwrites clarity content-area class with position relative for spinners to be centered and fix overflow to avoid vertical scrolling (especially for dashboard-options button)
.mac-content-area {
	overflow: hidden;
	overflow-y: auto;
	position: relative;
}

// clarity stack view as accordion
.mac-stackview {
	&--singlecolumn {
		.stack-block-label {
			max-width: 100%;
			flex-basis: 100%;
		}

		.stack-block-content {
			display: none;
		}
	}
}

// needed for stackviews inside of dialogs, clarity sets a fixed height there as default which may lead to double scroll bars
// needed to overwrite .modal .stack-view
// no other way to overwrite found
.mac-stackview {
	&--noscroll {
		.stack-view {
			height: auto !important;
		}
	}
}

@media (max-width: 959px) {
	.clr-wizard-stepnav-wrapper {
		display: none !important;
	}

	.modal-dialog {
		max-height: 100% !important;
		min-height: 100% !important;

		.modal {
			padding: 0.25rem;
		}
	}
}

// overwrite clarities form styles, to show ... when input value is too long for input width
input[type='email'],
input[type='text'],
input[type='url'] {
	text-overflow: ellipsis;
}

.svg-frontends-card {
	&--overflow-fix {
		//todo: remove important as soon as .card definition gets removed from style.scss
		overflow: visible !important;
	}
}

.mac-dropdown-toggle {
	display: block;
	overflow: hidden;
	padding-left: 0 !important;
}

// overwrite clarity header (barwrapper) styles
.header {
	.branding {
		min-width: 100px;
		padding: 0 0.5rem 0 1rem;
		position: relative;

		&::after {
			position: absolute;
			right: 0;
			content: '';
			display: inline-block;
			background: #fafafa;
			opacity: 0.15;
			height: 40px;
			width: 1px;
			top: 10px;
		}
	}
}

.header .header-actions > .dropdown > .dropdown-toggle,
.header .settings > .dropdown > .dropdown-toggle,
header .header-actions > .dropdown > .dropdown-toggle,
header .settings > .dropdown > .dropdown-toggle.mobile-name-tag {
	line-height: 20px;
	text-align: right;
}

@include media-breakpoint-down-sm {
	.header {
		.branding {
			min-width: auto;

			&::after {
				width: 0;
			}
		}
	}
}

@media print {
	.clr-vertical-nav {
		display: none;
	}

	body {
		transform: scale(1);
	}
}

// disable x besides vertical nav bar to close it
.clr-nav-close {
	display: none;
}

// fix arrow down icon in buttons positioned too high up
.ng-trigger cds-icon[shape='angle'][direction='down'] {
	top: 6px;
}

// create helper class to use when column should not be filterable and sortable
// without having to leave out "clrDgField" property because that removes it from file exports etc.
// if clarity ever allows to set the basically necessary clrDgField in datagrid columns while still allowing us to disabl filter/sort then remove this!
clr-dg-column.XG-clr-dg-col-no-sort-filter {
	& button {
		pointer-events: none !important;
	}

	& button:hover {
		cursor: unset !important;
		text-decoration: unset !important;
	}

	& clr-dg-filter button {
		display: none !important;
		// different possibility to hide
		// opacity: 0 !important;
	}
}
